









































































































































import { defineComponent, ref, watch } from "@vue/composition-api";
import PerawatanFM from "../models/perawatanForm";
import useFormModel from "@/apps/core/modules/useFormModel";
import router from "@/router";
import { Perawatan } from "../models/perawatan";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "PerawatanForm",
  props: { headerText: String },
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    PasienModalForm: () => import("../components/PasienModalForm.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const isPasienModalFormActive = ref(false);
    const noRm = ref("");
    const requiredFields = [
      "pasien",
      "tanggalMasuk",
      "jenisPerawatan",
      "jenisLokasi",
    ];
    const form = new PerawatanFM(requiredFields, ["kodeBill"]);
    const paramsId = router.currentRoute.params.id;
    const composition = useFormModel(form, paramsId);
    const perawatan = composition.instance as Perawatan;
    const formatOption = function(opt: Record<string, string>) {
      opt.nama = opt.no_rm + " - " + opt.nama;
      return opt;
    };

    const setPasien = (pasien?: Record<string, any>) => {
      perawatan.pasien = pasien ?? null;
      composition.validate("pasien");
      isPasienModalFormActive.value = false;
    };

    watch(
      () => perawatan.jenisPerawatan,
      (newValue) => {
        composition.errorMap.kodeBill = "";
        if (!paramsId) {
          perawatan.kodeBill = newValue === "inap" ? null : "000";
        }
      }
    );

    return {
      // Data
      isPasienModalFormActive,
      noRm,
      perawatan,

      // Composition
      ...composition,

      // Method
      formatOption,
      setPasien,
    };
  },
});
