import FormModel from "@/apps/core/models/formModel";
import { setRefreshRequest } from "../modules/stores/perawatan";
import { Perawatan, PerawatanAPI } from "./perawatan";

export default class PerawatanFM extends FormModel {
  constructor(requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(
      new PerawatanAPI(),
      new Perawatan(),
      requiredFields,
      nonRequiredFields,
      setRefreshRequest
    );
  }

  /*
  Yang akan digunakan di vue single file component adalah:
  - instance
  - errorMap
  - validity.getIsValid() (computed)
  - setEdited (method)
  - validate (method)
  - save (method)
  */
}
