



























































import { defineComponent, onActivated, reactive } from "@vue/composition-api";
import { listVM } from "../modules/stores/perawatan";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";

export default defineComponent({
  name: "PerawatanList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    PerawatanListBox: () => import("../components/PerawatanListBox.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const composition = useInfiniteScrollList(listVM, false);
    const filterButtonConfigs = reactive([
      {
        field: "verifikasi",
        value: "",
        valueMap: {
          "": "Verifikasi",
          Belum: "Belum Verifikasi",
          Ya: "Terverifikasi",
          Tidak: "Ditolak",
        },
      },
      {
        field: "jenisPerawatan",
        valueMap: {
          "": "Rawat",
          Jalan: "Rawat Jalan",
          Inap: "Rawat Inap",
        },
      },
    ]);

    onActivated(() => {
      if (listVM.getRefreshRequest() !== "") {
        // ada permintaan refresh list (dengan initial filtering)
        listVM.applyFilter(listVM.refreshRequest.filterMap);
        if (listVM.refreshRequest.filterMap) {
          // reset filtering buttons
          for (let idx = 0; idx < filterButtonConfigs.length; idx++) {
            filterButtonConfigs[idx].value = "";
          }
          const verifikasi = listVM.refreshRequest.filterMap.verifikasi;
          if (verifikasi) filterButtonConfigs[0].value = verifikasi;
        }
        listVM.resetRefreshRequest();
      } else if (listVM.list.length === 0) {
        // ketika listview pertama kali dipanggil, meload data list
        // asumsinya kalau list kosong, perlu fetch data dulu
        composition.onPageChange();
      }
    });

    return {
      // Data
      filterButtonConfigs,

      // Composition
      ...composition,
      ...useListFilter(),
    };
  },
});
