















































































import { defineComponent } from "@vue/composition-api";
import { listVM } from "../modules/stores/pasien";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import useListFilter from "@/apps/core/modules/useListFilter";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";

export default defineComponent({
  name: "PasienList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ListFilter: () => import("@/apps/core/components/ListFilter.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const filterButtonConfigs = [
      {
        field: "gender",
        valueMap: {
          "": "Gender",
          "Laki-Laki": "Laki-laki",
          Perempuan: "Perempuan",
        },
      },
    ];

    return {
      // Data
      filterButtonConfigs,

      // Method
      toUserDateFormat,

      // Composition
      // ...useListViewModel(listVM),
      ...useInfiniteScrollList(listVM),
      ...useListFilter(),
    };
  },
});
