




























import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import PasienVM from "../models/pasien";
import useViewModel from "@/apps/core/modules/useViewModel";

export default defineComponent({
  name: "PasienDetail",
  components: {
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const pasienVM = new PasienVM();
    const { viewModelRef, instance } = useViewModel(paramsId, pasienVM);
    return {
      // Data
      viewModelRef,
      pasien: instance,
      
      // Method
      toUserDateFormat,
    };
  },
});
