


































































































import { computed, defineComponent } from "@vue/composition-api";
import PerawatanVM, { Perawatan } from "../models/perawatan";
import router from "@/router";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";
import Vue from "vue";
import PerawatanFM from "../models/perawatanForm";
import useFormModel from "@/apps/core/modules/useFormModel";
import { debounce } from "lodash";
import { SaveContext } from "@/apps/core/models/formModel";
import { DialogProgrammatic as Dialog } from "buefy";
import { setRefreshRequest } from "../modules/stores/perawatan";

export default defineComponent({
  name: "AktivitasKlinisDetail",
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    PerawatanAktivitas: () =>
      import("@/apps/perawatan/components/PerawatanAktivitas.vue"),
    ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    LulusTag: () => import("@/apps/core/components/LulusTag.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const paramsId = router.currentRoute.params.id;
    const form = new PerawatanFM([], ["tanggalKeluar"]);
    const composition = useFormModel(form, paramsId);
    const instance = composition.instance;
    const perawatan = instance as Perawatan;
    const menuConfigs = computed(() => {
      const _list = [];
      if (instance.extraData?.canChange) {
        _list.push({
          label: "Ubah",
          onClick: () =>
            router.push({ name: "perawatan-change", params: { id: paramsId } }),
        });
      }

      if (instance.extraData?.canDelete) {
        _list.push({
          label: "Hapus",
          onClick: () => {
            Dialog.confirm({
              message: "Hapus perawatan ini?",
              onConfirm: async () => {
                const perawatanVM = new PerawatanVM();
                if (perawatan.id === null) return;
                await perawatanVM.api.delete(perawatan.id);
                setRefreshRequest("context-menu");
                router.go(-1);
              },
            });
          },
        });
      }
      return _list;
    });

    const showImages = (fotoList: Array<Record<string, string>>) => {
      const items: Array<Record<string, string>> = [];
      fotoList.forEach((foto: Record<string, string>) => {
        items.push({ src: foto.url });
      });
      Vue.prototype.$Pswp.open({ items: items });
    };

    const updateTglKeluar = debounce((value) => {
      form.validate(value);
      const dataParams = { "set_tgl_keluar": perawatan.tanggalKeluar };
      if (form.getIsValid().value)
        form.save(SaveContext.Update, false, true, dataParams);
    }, 1000);

    return {
      // Data
      perawatan,
      menuConfigs,

      // Composition
      ...composition,

      // Method
      showImages,
      toUserDateFormat,
      updateTglKeluar,
    };
  },
});
