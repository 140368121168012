









































































































import { defineComponent } from "@vue/composition-api";
import PasienFM from "../models/pasienForm";
import useFormModel from "@/apps/core/modules/useFormModel";

/* Hanya create saja, tanpa updated, tidak perlu paramsId dari router */
export default defineComponent({
  name: "PasienForm",
  props: { headerText: String },
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    // BUAT CONTOH!!!! -> const isCreate = !has(router.currentRoute.params.id, "id");
    const requiredFields = ["noRm", "nama", "gender", "tglLahir"];
    const nonRequiredFields = ["catatan"];
    const form = new PasienFM(requiredFields, nonRequiredFields);
    const composition = useFormModel(form);
    const pasien = composition.instance;

    return {
      // Data
      pasien,

      // Composition
      ...composition,
    };
  },
});
